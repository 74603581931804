const Images = {
    one: require('./1.png').default,
    two: require('./2.png').default,
    three: require('./3.png').default,
    four: require('./4.png').default,
    five: require('./5.png').default,
    home: require('./home.png').default,
    logo: require('./logo.png').default,
    phone: require('./phone.png').default,
    customerExp: require('./customerExperience.png').default,
    statistics1: require('./statistics1.png').default,
    statistics2: require('./statistics2.png').default,
    discoverBtn: require('./discoverbtn.png').default,
    exp: require('./exp.png').default,
    readMore: require('./readmore.png').default,
    plan: require('./plan.png').default,
    work: require('./work.png').default,
    listen: require('./listen.png').default,
    feedback: require('./feedback.png').default,
    line: require('./line.png').default,
    testimonial: require('./testimonial.png').default,
    facebook: require('./facebook.png').default,
    twitter: require('./twitter.png').default,
    instagram: require('./instagram.png').default,
    email: require('./email.png').default,
    phone2: require('./phone2.png').default,
    location: require('./location.png').default,
    artboard: require('./artboard.png').default,
    about: require('./about.png').default,
    contactBtn: require('./contactBtn.png').default,
    priceBtn: require('./priceBtn.png').default,
    slide1: require('./slide1.png').default,
    slide2: require('./slide2.png').default,
    slide3: require('./slide3.png').default,
    slide4: require('./slide4.png').default,
    linkedin: require('./linkedin.png').default,
    slide1new: require('./slide1.1.png').default,
    slide2new: require('./slide2.1.png').default,
    slide3new: require('./slide3.1.png').default,
    slide4new: require('./slide4.1.png').default,

}

export default Images;